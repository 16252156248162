const jsContentTypes = [
  'text/javascript',
  'application/javascript',
  'application/ecmascript',
  'application/x-ecmascript',
];
const evalFns = ['DOMEval', 'globalEval'];

// check if the last frame before the error was an eval
// check if the last breadcrumb was a legacy js response
const isLegacyJSResponse = (frames = [], bcData = {}) => {
  const checkFrame = frames[frames.length - 2];
  const lastFrame = frames[frames.length - 1];

  return (
    checkFrame &&
    bcData?.legacy &&
    evalFns.some((name) => checkFrame.function.indexOf(name) !== -1) &&
    (lastFrame.filename === '<anonymous>' ||
      lastFrame.function === '<anonymous>')
  );
};

export const setBreadcrumbLegacyJSResponse = (breadcrumb, hint) => {
  const xhr = hint?.xhr;

  if (xhr) {
    const contentType = xhr.getResponseHeader('Content-Type');

    // this is a legacy js response
    if (jsContentTypes.some((c) => contentType.indexOf(c) !== -1)) {
      breadcrumb.data.contentType = contentType;
      breadcrumb.data.legacy = true;
      breadcrumb.data.response = xhr.responseText;
    }
  }

  return breadcrumb;
};

// @todo
// @tobiasz: refactor this feature to use ts + failsafe approach.
export const setEventLegacyJSResponse = (event) => {
  try {
    const error = (event?.exception?.values ?? [])[0];
    const frames = error?.stacktrace?.frames ?? [];
    const lastFrame = frames[frames.length - 1];
    const bcData = event.breadcrumbs[event.breadcrumbs.length - 1]?.data;

    if (isLegacyJSResponse(frames, bcData)) {
      const lines = bcData.response.split('\n');
      const lineno = lastFrame.lineno - 1;

      lastFrame.context_line = lines[lineno];
      lastFrame.filename = bcData.url;
      lastFrame.pre_context = lines.slice(Math.min(lineno - 6, 0), lineno - 1);
      lastFrame.post_context = lines.slice(lineno + 1, lineno + 6);

      event.tags = {
        ...(event.tags ?? {}),
        legacyEval: true,
      };
    }

    return event;
  } catch {
    return event;
  }
};
