const setOfficeTag = (event) => {
  if (event.request?.url) {
    const { pathname } = new URL(event.request.url);
    let office = 'publicoffice';

    if (pathname.indexOf('backoffice') !== -1) {
      office = 'backoffice';
    } else if (event.user?.id) {
      office = 'frontoffice';
    }

    event.tags = {
      ...(event.tags ?? {}),
      office,
    };
  }
};

export default setOfficeTag;
