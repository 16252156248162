// From https://gist.github.com/impressiver/5092952
const commonIgnoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
];

// Custom ignore errors
const ignoreErrors = [
  /GEOCODER_GEOCODE: ZERO_RESULTS/i,
  /GEOCODER_GEOCODE: OVER_QUERY_LIMIT/i,
  /Geocoder failed due to: ERROR/i,

  /Loading CSS/i,

  /AbortError: The operation was aborted/i,
  /Request aborted/i,
  /Network Error/i,
  /NetworkError/,
  /Request failed with status code/i,
  /timeout of 0ms exceeded/i,

  /Unexpected end of JSON input/i,
  /Unexpected token < in JSON at position 0/i,

  /Syntax error/i,
  /Type error/i,

  /ResizeObserver loop limit exceeded/i,
  /ResizeObserver loop completed with undelivered notifications/i,

  /NotAllowedError: The play method is not allowed by the user agent or the platform in the current context, possibly because the user denied permission/i,

  // SendbirdError
  // https://community.sendbird.com/t/sendbird-uikit-react-v2-with-nextjs-error-command-received-no-ack/5025
  /Command received no ack/i,
  /Connection is closed\. Please reconnect\./i,

  // Errors caused by the Microsoft Outlook SafeLink crawler
  /Non-Error promise rejection captured with value: Object Not Found Matching Id:\d+, MethodName:[^,]+, ParamCount:\d+/i,
];

export default [...commonIgnoreErrors, ...ignoreErrors];
