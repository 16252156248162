import { Globals } from 'react-spring';

import * as errorReporting from 'hb-react/shared/utils/errorReporting';

// This variable is injected by webpack with all env variables need for the project
// eslint-disable-next-line no-undef
const envs = __HB_ENV__;

// Don't do that on Storybook
if (!process.env.STORYBOOK_ENV) {
  // Change the public path "On The Fly" https://webpack.js.org/guides/public-path/#on-the-fly
  // eslint-disable-next-line no-undef, camelcase
  __webpack_public_path__ = window.__HB_ENV__.PUBLIC_PATH;
}

if (process.env.STORYBOOK_ENV) {
  // eslint-disable-next-line no-undef
  window.__HB_ENV__ = __HB_ENV__;
}

errorReporting.initSentry(window.__HB_ENV__);

// Set some context for Sentry from this file app/views/layouts/_javascripts.html.haml
if (window.__HB_USER__ || window.__HB_NETWORK__) {
  errorReporting.setContext({
    ...(window.__HB_USER__ && {
      user: window.__HB_USER__,
    }),
    ...(window.__HB_NETWORK__ && {
      network: window.__HB_NETWORK__,
    }),
  });
}

const isTest = envs.ENVIRONMENT === 'test';

// Skip all animation from react-spring on e2e tests
if (isTest) {
  Globals.assign({
    skipAnimation: true,
  });
}
